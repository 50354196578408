/* Extra Pequeno (XS) - Smartphones em modo retrato */
.App {
  font-family: Arial, sans-serif;
  text-align: center;
  color: #333;
}

/* Pequeno (SM) - Smartphones em modo paisagem e tablets em modo retrato */
@media (min-width: 576px) {
  .App {
    text-align: center; /* Mantém a centralização do texto */
  }
}

/* Médio (MD) - Tablets em modo paisagem e dispositivos intermediários */
@media (min-width: 768px) {
  .App {
    text-align: center; /* Mantém a centralização do texto */
  }
}

/* Grande (LG) - Laptops e monitores de tamanho médio */
@media (min-width: 992px) {
  .App {
    text-align: center; /* Mantém a centralização do texto */
  }
}

/* Extra Grande (XL) - Monitores de desktop maiores e telas de alta resolução */
@media (min-width: 1200px) {
  .App {
    text-align: center; /* Mantém a centralização do texto */
  }
}

