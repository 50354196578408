.cartao-aposta {
  display: flex; /* Define um layout flexível para o card */
  justify-content: space-between; /* Espaça os itens de forma uniforme entre o início e o fim do contêiner */
  align-items: center; /* Alinha os itens ao centro verticalmente */
  background-color: #fff; /* Define a cor de fundo do card como branco */
  padding: 20px; /* Adiciona espaçamento interno ao card */
  border: 2px solid #ff6600; /* Define a borda do card */
  border-radius: 10px; /* Adiciona bordas arredondadas ao card */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Adiciona sombra ao card */
  height: 100px; /* Define a altura do card */
  width: 100%; /* Define a largura do card */
  max-width: 1200px; /* Define a largura máxima do card */
  margin: 10px auto; /* Centraliza o card horizontalmente e adiciona margem vertical */
  transition: all 0.3s ease; /* Adiciona transição suave para todas as propriedades */
  text-decoration: none; /* Remove decoração de link padrão */
  color: inherit; /* Herda a cor do texto padrão */
}

.cartao-aposta:hover {
  transform: translateY(-5px); /* Efeito de levantamento ao passar o mouse */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Sombra mais pronunciada */
}

.cartao-aposta .secao-logo {
  display: flex; /* Define um layout flexível para a seção do logo */
  justify-content: center; /* Centraliza o logo horizontalmente */
  align-items: center; /* Alinha o logo ao centro verticalmente */
  padding: 0; /* Remove o padding padrão */
  margin: 0; /* Remove as margens padrão */
}

.cartao-aposta .logo-bet69 {
  max-width: 100%; /* Garante que o logo não ultrapasse o card */
  height: 130px; /* Define a altura do logo */
  padding: 0; /* Remove o padding padrão */
  margin: 0; /* Remove as margens padrão */  
  border-radius: 50%; /* Transforma a imagem em um círculo */  
  object-fit: cover; /* Garante que a imagem se ajuste ao contêiner circular */
}

.cartao-aposta .secao-bonus {
  flex: 1; /* Define que a seção do bônus ocupará uma fração do espaço disponível */
  text-align: center; /* Centraliza o texto da seção do bônus */
  margin: 0; /* Remove as margens padrão */
  line-height: 1.2; /* Ajusta o espaçamento entre linhas */
}

.cartao-aposta .secao-bonus p {
  flex: 1; /* Define que o parágrafo ocupará uma fração do espaço disponível */
  text-align: center; /* Centraliza o texto do parágrafo */
  font-size: 20px; /* Define o tamanho da fonte do parágrafo */
  margin: 0; /* Remove as margens padrão */
}

.cartao-aposta .quantidade-bonus {
  color: #ff8c00; /* Define a cor do texto do valor do bônus */
  font-size: 1.5em; /* Define o tamanho da fonte do valor do bônus */
  font-weight: bold; /* Define o peso da fonte do valor do bônus */
  margin: 0; /* Remove as margens padrão */
}

.cartao-aposta .secao-metodos-pagamento {
  flex: 1; /* Define que a seção dos métodos de pagamento ocupará uma fração do espaço disponível */
  display: flex; /* Define um layout flexível para a seção dos métodos de pagamento */
  align-items: center; /* Alinha os itens ao centro verticalmente */
  justify-content: center; /* Centraliza os itens horizontalmente */
}

.cartao-aposta .secao-metodos-pagamento img {
  height: 10px; /* Define a altura das imagens dos métodos de pagamento */
  margin: 0 5px; /* Adiciona margem horizontal entre as imagens */
}

.cartao-aposta .secao-metodos-pagamento .metodo-pix {
  height: 50px; /* Define a altura da imagem do Pix */
}

.cartao-aposta .secao-metodos-pagamento p {
  margin: 0; /* Remove as margens padrão */
  font-size: 0.9em; /* Define o tamanho da fonte do texto */
  color: #ff8800; /* Define a cor do texto */
}

.cartao-aposta .secao-avaliacao {
  flex: 1; /* Define que a seção de avaliação ocupará uma fração do espaço disponível */
  text-align: center; /* Centraliza o texto da seção de avaliação */
}

.cartao-aposta .secao-avaliacao span {
  color: #ff8c00; /* Define a cor do texto das estrelas */
  font-size: 1.2em; /* Define o tamanho da fonte das estrelas */
}

.cartao-aposta .secao-avaliacao p {
  margin: 0; /* Remove as margens padrão */
  font-size: 0.9em; /* Define o tamanho da fonte do texto */
  color: #777; /* Define a cor do texto */
}

.cartao-aposta .secao-acao {
  flex: 1; /* Define que a seção de ação ocupará uma fração do espaço disponível */
  text-align: center; /* Centraliza o texto da seção de ação */
}

.cartao-aposta .botao-apostar {
  background-color: #ff8c00; /* Define a cor de fundo do botão */
  color: #fff; /* Define a cor do texto do botão */
  padding: 10px 20px; /* Adiciona padding interno ao botão */
  border: none; /* Remove a borda padrão do botão */
  border-radius: 5px; /* Adiciona bordas arredondadas ao botão */
  cursor: pointer; /* Define o cursor como ponteiro ao passar o mouse sobre o botão */
  font-size: 1em; /* Define o tamanho da fonte do botão */
  font-weight: bold; /* Define o peso da fonte do botão */
}

.cartao-aposta:hover .botao-apostar {
  background-color: #000000; /* Altera a cor do botão ao passar o mouse */
}

/* Extra Pequeno (XS) - Smartphones em modo retrato */
@media (max-width: 575px) {
  .cartao-aposta {
    flex-direction: column; /* Alinha os itens verticalmente */
    height: auto; /* Ajusta a altura do card */
    padding: 15px; /* Ajusta o padding interno */
    max-width: 100%; /* Remove a largura máxima */
  }

  .cartao-aposta .secao-logo, 
  .cartao-aposta .secao-bonus, 
  .cartao-aposta .secao-metodos-pagamento, 
  .cartao-aposta .secao-avaliacao, 
  .cartao-aposta .secao-acao {
    width: 100%; /* Faz com que cada seção ocupe toda a largura disponível */
    text-align: center; /* Centraliza o texto */
  }

  .cartao-aposta .logo-bet69 {
    height: 100px; /* Ajusta a altura do logo */
  }

  .cartao-aposta .secao-bonus p {
    font-size: 18px; /* Ajusta o tamanho da fonte do parágrafo */
  }

  .cartao-aposta .quantidade-bonus {
    font-size: 1.2em; /* Ajusta o tamanho da fonte do valor do bônus */
  }

  .cartao-aposta .secao-metodos-pagamento img {
    height: 20px; /* Ajusta a altura das imagens dos métodos de pagamento */
  }

  .cartao-aposta .secao-avaliacao span {
    font-size: 1em; /* Ajusta o tamanho da fonte das estrelas */
  }

  .cartao-aposta .secao-avaliacao p {
    font-size: 0.8em; /* Ajusta o tamanho da fonte do texto */
  }

  .cartao-aposta .botao-apostar {
    font-size: 0.9em; /* Ajusta o tamanho da fonte do botão */
    padding: 8px 16px; /* Ajusta o padding interno do botão */
  }
}

/* Pequeno (SM) - Smartphones em modo paisagem e tablets em modo retrato */
@media (min-width: 576px) and (max-width: 767px) {
  .cartao-aposta {
    flex-direction: column; /* Alinha os itens verticalmente */
    height: auto; /* Ajusta a altura do card */
    padding: 20px; /* Ajusta o padding interno */
    max-width: 100%; /* Remove a largura máxima */
  }

  .cartao-aposta .logo-bet69 {
    height: 110px; /* Ajusta a altura do logo */
  }

  .cartao-aposta .secao-bonus p {
    font-size: 18px; /* Ajusta o tamanho da fonte do parágrafo */
  }

  .cartao-aposta .quantidade-bonus {
    font-size: 1.3em; /* Ajusta o tamanho da fonte do valor do bônus */
  }

  .cartao-aposta .secao-metodos-pagamento img {
    height: 25px; /* Ajusta a altura das imagens dos métodos de pagamento */
  }

  .cartao-aposta .secao-avaliacao span {
    font-size: 1.1em; /* Ajusta o tamanho da fonte das estrelas */
  }

  .cartao-aposta .secao-avaliacao p {
    font-size: 0.85em; /* Ajusta o tamanho da fonte do texto */
  }

  .cartao-aposta .botao-apostar {
    font-size: 0.95em; /* Ajusta o tamanho da fonte do botão */
    padding: 10px 18px; /* Ajusta o padding interno do botão */
  }
}

/* Médio (MD) - Tablets em modo paisagem e dispositivos de tamanho intermediário */
@media (min-width: 768px) and (max-width: 991px) {
  .cartao-aposta {
    flex-direction: row; /* Alinha os itens horizontalmente */
    height: 120px; /* Ajusta a altura do card */
    padding: 20px; /* Mantém o padding interno */
    max-width: 100%; /* Remove a largura máxima */
  }

  .cartao-aposta .logo-bet69 {
    height: 120px; /* Ajusta a altura do logo */
  }

  .cartao-aposta .secao-bonus p {
    font-size: 20px; /* Mantém o tamanho da fonte do parágrafo */
  }

  .cartao-aposta .quantidade-bonus {
    font-size: 1.4em; /* Ajusta o tamanho da fonte do valor do bônus */
  }

  .cartao-aposta .secao-metodos-pagamento img {
    height: 30px; /* Ajusta a altura das imagens dos métodos de pagamento */
  }

  .cartao-aposta .secao-avaliacao span {
    font-size: 1.2em; /* Mantém o tamanho da fonte das estrelas */
  }

  .cartao-aposta .secao-avaliacao p {
    font-size: 0.9em; /* Mantém o tamanho da fonte do texto */
  }

  .cartao-aposta .botao-apostar {
    font-size: 1em; /* Mantém o tamanho da fonte do botão */
    padding: 10px 20px; /* Mantém o padding interno do botão */
  }
}

/* Grande (LG) - Laptops e monitores de tamanho médio */
@media (min-width: 992px) and (max-width: 1199px) {
  .cartao-aposta {
    height: 130px; /* Ajusta a altura do card */
    padding: 20px; /* Mantém o padding interno */
  }

  .cartao-aposta .logo-bet69 {
    height: 130px; /* Mantém a altura do logo */
  }

  .cartao-aposta .secao-bonus p {
    font-size: 20px; /* Mantém o tamanho da fonte do parágrafo */
  }

  .cartao-aposta .quantidade-bonus {
    font-size: 1.5em; /* Mantém o tamanho da fonte do valor do bônus */
  }

  .cartao-aposta .secao-metodos-pagamento img {
    height: 35px; /* Ajusta a altura das imagens dos métodos de pagamento */
  }

  .cartao-aposta .secao-avaliacao span {
    font-size: 1.3em; /* Ajusta o tamanho da fonte das estrelas */
  }

  .cartao-aposta .secao-avaliacao p {
    font-size: 0.9em; /* Mantém o tamanho da fonte do texto */
  }

  .cartao-aposta .botao-apostar {
    font-size: 1.1em; /* Ajusta o tamanho da fonte do botão */
    padding: 12px 24px; /* Ajusta o padding interno do botão */
  }
}

/* Extra Grande (XL) - Monitores de desktop maiores e telas de alta resolução */
@media (min-width: 1200px) {
  .cartao-aposta {
    height: 150px; /* Ajusta a altura do card */
    padding: 20px; /* Mantém o padding interno */
  }

  .cartao-aposta .logo-bet69 {
    height: 150px; /* Ajusta a altura do logo */
  }

  .cartao-aposta .secao-bonus p {
    font-size: 22px; /* Ajusta o tamanho da fonte do parágrafo */
  }

  .cartao-aposta .quantidade-bonus {
    font-size: 1.6em; /* Ajusta o tamanho da fonte do valor do bônus */
  }

  .cartao-aposta .secao-metodos-pagamento img {
    height: 40px; /* Ajusta a altura das imagens dos métodos de pagamento */
  }

  .cartao-aposta .secao-avaliacao span {
    font-size: 1.4em; /* Ajusta o tamanho da fonte das estrelas */
  }

  .cartao-aposta .secao-avaliacao p {
    font-size: 1em; /* Ajusta o tamanho da fonte do texto */
  }

  .cartao-aposta .botao-apostar {
    font-size: 1.2em; /* Ajusta o tamanho da fonte do botão */
    padding: 14px 28px; /* Ajusta o padding interno do botão */
  }
}
