/* Extra Pequeno (XS) - Smartphones em modo retrato */
.PoliticaPrivacidade p {
  margin: 0 10px;
  text-align: justify;
}

.bullet-list {
  margin: 0 10px;
  list-style-position: inside;
  padding-left: 0;
}

.bullet-list li {
  text-align: justify;
  margin-left: 0;
  padding-left: 0;
}

/* Pequeno (SM) - Smartphones em modo paisagem e tablets em modo retrato */
@media (min-width: 576px) {
  .PoliticaPrivacidade p {
    margin: 0 20px;
  }

  .bullet-list {
    margin: 0 20px;
  }
}

/* Médio (MD) - Tablets em modo paisagem e dispositivos intermediários */
@media (min-width: 768px) {
  .PoliticaPrivacidade p {
    margin: 0 40px;
  }

  .bullet-list {
    margin: 0 40px;
  }
}

/* Grande (LG) - Laptops e monitores de tamanho médio */
@media (min-width: 992px) {
  .PoliticaPrivacidade p {
    margin: 0 100px;
  }

  .bullet-list {
    margin: 0 100px;
  }
}

/* Extra Grande (XL) - Monitores de desktop maiores e telas de alta resolução */
@media (min-width: 1200px) {
  .PoliticaPrivacidade p {
    margin: 0 200px;
  }

  .bullet-list {
    margin: 0 200px;
  }
}

