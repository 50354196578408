/* Estilos globais */
.About {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin: 0 auto;
  max-width: 1200px; /* Largura máxima aplicada em todas as telas */
}

.column {
  flex: 1;
  min-width: 300px;
}

.column h2 {
  margin-top: 0;
}

.column p {
  text-align: justify;
  margin-bottom: 20px;
}

.text-about {
  padding-left: 20px;
  list-style-type: disc;
}

.text-about li {
  margin-bottom: 10px;
  text-align: left;
}

/* Extra Pequeno (XS) - Smartphones em modo retrato */
@media (max-width: 575px) {
  .About {
    flex-direction: column; /* Ajusta para uma única coluna em telas muito pequenas */
  }
  
  .column {
    min-width: 100%; /* Coluna ocupa toda a largura */
  }
}

/* Pequeno (SM) - Smartphones em modo paisagem e tablets em modo retrato */
@media (min-width: 576px) and (max-width: 767px) {
  .About {
    justify-content: space-around; /* Ajusta a distribuição das colunas */
  }
  
  .column {
    min-width: 45%; /* Colunas ocupam cerca de metade da tela */
  }
}

/* Médio (MD) - Tablets em modo paisagem e dispositivos intermediários */
@media (min-width: 768px) and (max-width: 991px) {
  .About {
    justify-content: space-between;
  }
  
  .column {
    min-width: 45%; /* Colunas permanecem próximas de metade da tela */
  }
}

/* Grande (LG) - Laptops e monitores de tamanho médio */
@media (min-width: 992px) and (max-width: 1199px) {
  .About {
    max-width: 1000px; /* Ajuste a largura máxima do container para telas médias */
  }
  
  .column {
    min-width: 30%; /* Colunas são menores, permitindo mais espaço para conteúdo adicional */
  }
}

/* Extra Grande (XL) - Monitores de desktop maiores e telas de alta resolução */
@media (min-width: 1200px) {
  .About {
    max-width: 1200px; /* Largura máxima original */
  }
  
  .column {
    min-width: 300px; /* Mantém a largura mínima original */
  }
}

