.cartao-aposta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 20px;
  border: 2px solid #ff6600;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 100px;
  width: 100%;
  max-width: 1200px;
  margin: 10px auto;
  transition: all 0.3s ease;
  text-decoration: none;
  color: inherit;
}

.cartao-aposta:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.cartao-aposta .secao-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

.cartao-aposta .logo-betdasorte {
  max-width: 100%;
  height: 90px;
  padding: 0;
  margin: 20px;
  border-radius: 50%;
  object-fit: cover;
}

.cartao-aposta .secao-bonus {
  flex: 1;
  text-align: center;
  margin: 0;
  line-height: 1.2;
}

.cartao-aposta .secao-bonus p {
  flex: 1;
  text-align: center;
  font-size: 20px;
  margin: 0;
}

.cartao-aposta .quantidade-bonus {
  color: #ff8c00;
  font-size: 1.5em;
  font-weight: bold;
  margin: 0;
}

.cartao-aposta .secao-metodos-pagamento {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cartao-aposta .secao-metodos-pagamento img {
  height: 10px;
  margin: 0 5px;
}

.cartao-aposta .secao-metodos-pagamento .metodo-pix {
  height: 50px;
}

.cartao-aposta .secao-avaliacao {
  flex: 1;
  text-align: center;
}

.cartao-aposta .secao-avaliacao p {
  margin: 0;
  font-size: 0.9em;
  color: #777;
}

.cartao-aposta .secao-acao {
  flex: 1;
  text-align: center;
}

.cartao-aposta .botao-apostar {
  background-color: #ff8c00;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  font-weight: bold;
}

.cartao-aposta:hover .botao-apostar {
  background-color: #000000;
}

/* Extra Pequeno (XS) - Smartphones em modo retrato */
@media (max-width: 575px) {
  .cartao-aposta {
    flex-direction: column;
    height: auto;
    padding: 15px;
    max-width: 100%;
  }

  .cartao-aposta .secao-logo,
  .cartao-aposta .secao-bonus,
  .cartao-aposta .secao-metodos-pagamento,
  .cartao-aposta .secao-avaliacao,
  .cartao-aposta .secao-acao {
    width: 100%;
    text-align: center;
  }

  .cartao-aposta .logo-betdasorte {
    height: 80px;
    margin: 10px;
  }

  .cartao-aposta .secao-bonus p {
    font-size: 18px;
  }

  .cartao-aposta .quantidade-bonus {
    font-size: 1.3em;
  }

  .cartao-aposta .secao-metodos-pagamento img {
    height: 20px;
  }

  .cartao-aposta .secao-avaliacao p {
    font-size: 0.85em;
  }

  .cartao-aposta .botao-apostar {
    font-size: 0.9em;
    padding: 8px 16px;
  }
}

/* Pequeno (SM) - Smartphones em modo paisagem e tablets em modo retrato */
@media (min-width: 576px) and (max-width: 767px) {
  .cartao-aposta {
    flex-direction: column;
    height: auto;
    padding: 20px;
    max-width: 100%;
  }

  .cartao-aposta .logo-betdasorte {
    height: 90px;
    margin: 15px;
  }

  .cartao-aposta .secao-bonus p {
    font-size: 18px;
  }

  .cartao-aposta .quantidade-bonus {
    font-size: 1.4em;
  }

  .cartao-aposta .secao-metodos-pagamento img {
    height: 25px;
  }

  .cartao-aposta .secao-avaliacao p {
    font-size: 0.9em;
  }

  .cartao-aposta .botao-apostar {
    font-size: 0.95em;
    padding: 10px 18px;
  }
}

/* Médio (MD) - Tablets em modo paisagem e dispositivos de tamanho intermediário */
@media (min-width: 768px) and (max-width: 991px) {
  .cartao-aposta {
    flex-direction: row;
    height: 120px;
    padding: 20px;
    max-width: 100%;
  }

  .cartao-aposta .logo-betdasorte {
    height: 100px;
  }

  .cartao-aposta .secao-bonus p {
    font-size: 20px;
  }

  .cartao-aposta .quantidade-bonus {
    font-size: 1.5em;
  }

  .cartao-aposta .secao-metodos-pagamento img {
    height: 30px;
  }

  .cartao-aposta .secao-avaliacao p {
    font-size: 0.9em;
  }

  .cartao-aposta .botao-apostar {
    font-size: 1em;
    padding: 10px 20px;
  }
}

/* Grande (LG) - Laptops e monitores de tamanho médio */
@media (min-width: 992px) and (max-width: 1199px) {
  .cartao-aposta {
    height: 130px;
    padding: 20px;
  }

  .cartao-aposta .logo-betdasorte {
    height: 120px;
  }

  .cartao-aposta .secao-bonus p {
    font-size: 20px;
  }

  .cartao-aposta .quantidade-bonus {
    font-size: 1.6em;
  }

  .cartao-aposta .secao-metodos-pagamento img {
    height: 35px;
  }

  .cartao-aposta .secao-avaliacao p {
    font-size: 0.9em;
  }

  .cartao-aposta .botao-apostar {
    font-size: 1.1em;
    padding: 12px 24px;
  }
}

/* Extra Grande (XL) - Monitores de desktop maiores e telas de alta resolução */
@media (min-width: 1200px) {
  .cartao-aposta {
    height: 150px;
    padding: 20px;
  }

  .cartao-aposta .logo-betdasorte {
    height: 130px;
  }

  .cartao-aposta .secao-bonus p {
    font-size: 22px;
  }

  .cartao-aposta .quantidade-bonus {
    font-size: 1.7em;
  }

  .cartao-aposta .secao-metodos-pagamento img {
    height: 40px;
  }

  .cartao-aposta .secao-avaliacao p {
    font-size: 1em;
  }

  .cartao-aposta .botao-apostar {
    font-size: 1.2em;
    padding: 14px 28px;
  }
}
