/* Estilos globais para o cabeçalho */
.Header {
  display: flex; /* Define um layout flexível para o header */
  justify-content: space-between; /* Espaça os itens de forma uniforme entre o início e o fim do contêiner */
  align-items: center; /* Alinha os itens ao centro verticalmente */
  padding: 50px 20px; /* Adiciona espaçamento interno ao header */
  background-color: #000000; /* Define a cor de fundo do header como preto */
  margin: 0; /* Remove margens */
  height: 110px; /* Define a altura do header */
}

.logo-container {
  flex: 1; /* Define que o contêiner do logo ocupará uma fração do espaço disponível */
}

.logo {
  height: 80px; /* Ajusta a altura do logo */
  margin: 0; /* Remove margens */
  padding-left: 20px; /* Ajusta o padding à esquerda do logo */
}

.nav {
  flex: 2; /* Define que a navegação ocupará duas frações do espaço disponível */
  text-align: center; /* Centraliza o texto dentro do contêiner de navegação */
}

.nav-list {
  list-style: none; /* Remove os marcadores da lista */
  padding: 0; /* Remove o padding da lista */
  margin: 0; /* Remove as margens da lista */
  display: flex; /* Define um layout flexível para a lista */
  justify-content: center; /* Centraliza os itens da lista */
  gap: 20px; /* Define o espaço entre os itens da lista */
}

.nav-item {
  display: inline; /* Exibe o item da lista como um elemento inline */
}

.nav-link {
  color: #fff; /* Define a cor do texto do link como branco */
  text-decoration: none; /* Remove a decoração padrão do link (sublinhado) */
  font-size: 12px; /* Ajusta o tamanho da fonte dos links */
  padding: 10px 15px; /* Adiciona padding interno aos links */
  transition: background-color 0.3s ease; /* Adiciona uma transição suave para a mudança de cor de fundo */
}

.nav-link:hover {
  background-color: #ff8903; /* Define a cor de fundo do link ao passar o mouse */
  border-radius: 5px; /* Adiciona bordas arredondadas ao link */
}

.update-info {
  flex: 1; /* Define que o contêiner de atualização ocupará uma fração do espaço disponível */
  display: flex; /* Define um layout flexível para o contêiner */
  justify-content: center; /* Centraliza os itens horizontalmente */
  align-items: center; /* Alinha os itens ao centro verticalmente */
  text-align: center; /* Centraliza o texto */
}

.update-info span {
  font-size: 12px; /* Ajusta o tamanho da fonte do texto de atualização */
  color: white; /* Define a cor do texto como branco */
}

/* Extra Pequeno (XS) - Smartphones em modo retrato */
@media (max-width: 575px) {
  .Header {
    flex-direction: column; /* Alinha os itens verticalmente */
    height: auto; /* Ajusta a altura do header */
    padding: 20px; /* Ajusta o padding interno */
  }

  .logo-container, .nav, .update-info {
    flex: none; /* Remove a flexibilidade dos contêineres */
    width: 100%; /* Faz com que ocupem toda a largura disponível */
    text-align: center; /* Centraliza o texto */
    margin-bottom: 10px; /* Adiciona margem inferior */
  }

  .logo {
    height: 60px; /* Ajusta a altura do logo para telas pequenas */
    padding-left: 0; /* Remove o padding à esquerda do logo */
  }

  .nav-list {
    flex-direction: column; /* Alinha os itens da lista verticalmente */
    gap: 10px; /* Reduz o espaço entre os itens da lista */
  }

  .nav-link {
    font-size: 14px; /* Ajusta o tamanho da fonte dos links */
    padding: 5px 10px; /* Ajusta o padding interno dos links */
  }

  .update-info span {
    font-size: 14px; /* Ajusta o tamanho da fonte do texto de atualização */
  }
}

/* Pequeno (SM) - Smartphones em modo paisagem e tablets em modo retrato */
@media (min-width: 576px) and (max-width: 767px) {
  .Header {
    flex-direction: column; /* Alinha os itens verticalmente */
    height: auto; /* Ajusta a altura do header */
    padding: 30px 20px; /* Ajusta o padding interno */
  }

  .logo {
    height: 70px; /* Ajusta a altura do logo */
    padding-left: 10px; /* Ajusta o padding à esquerda do logo */
  }

  .nav-list {
    flex-direction: column; /* Alinha os itens da lista verticalmente */
    gap: 15px; /* Ajusta o espaço entre os itens da lista */
  }

  .nav-link {
    font-size: 16px; /* Ajusta o tamanho da fonte dos links */
    padding: 8px 15px; /* Ajusta o padding interno dos links */
  }

  .update-info span {
    font-size: 16px; /* Ajusta o tamanho da fonte do texto de atualização */
  }
}

/* Médio (MD) - Tablets em modo paisagem e dispositivos intermediários */
@media (min-width: 768px) and (max-width: 991px) {
  .Header {
    height: auto; /* Ajusta a altura do header */
    padding: 40px 20px; /* Ajusta o padding interno */
  }

  .logo {
    height: 80px; /* Ajusta a altura do logo */
    padding-left: 20px; /* Ajusta o padding à esquerda do logo */
  }

  .nav-link {
    font-size: 16px; /* Ajusta o tamanho da fonte dos links */
  }

  .update-info span {
    font-size: 16px; /* Ajusta o tamanho da fonte do texto de atualização */
  }
}

/* Grande (LG) - Laptops e monitores de tamanho médio */
@media (min-width: 992px) and (max-width: 1199px) {
  .Header {
    height: 110px; /* Mantém a altura do header */
    padding: 50px 20px; /* Mantém o padding interno */
  }

  .logo {
    height: 100px; /* Ajusta a altura do logo */
    padding-left: 30px; /* Ajusta o padding à esquerda do logo */
  }

  .nav-link {
    font-size: 16px; /* Mantém o tamanho da fonte dos links */
  }

  .update-info span {
    font-size: 16px; /* Mantém o tamanho da fonte do texto de atualização */
  }
}

/* Extra Grande (XL) - Monitores de desktop maiores e telas de alta resolução */
@media (min-width: 1200px) {
  .Header {
    height: 110px; /* Mantém a altura do header */
    padding: 50px 20px; /* Mantém o padding interno */
  }

  .logo {
    height: 120px; /* Ajusta a altura do logo */
    padding-left: 40px; /* Ajusta o padding à esquerda do logo */
  }

  .nav-link {
    font-size: 16px; /* Mantém o tamanho da fonte dos links */
  }

  .update-info span {
    font-size: 16px; /* Mantém o tamanho da fonte do texto de atualização */
  }
}

