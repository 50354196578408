/* Estilos globais para todos os dispositivos */
body {
  margin: 0; /* Remove a margem padrão do corpo da página */
  padding: 0; /* Remove o preenchimento padrão do corpo da página */
  font-family: Arial, sans-serif; /* Define a família de fontes para todo o conteúdo do corpo */
}

* {
  box-sizing: border-box; /* Inclui o padding e a borda no cálculo da largura e altura */
}

/* Pequeno (SM) - Smartphones em modo paisagem e tablets em modo retrato */
@media (min-width: 576px) {
  body {
    /* Mantém as mesmas propriedades */
  }
  
  * {
    /* Mantém o box-sizing */
  }
}

/* Médio (MD) - Tablets em modo paisagem e dispositivos intermediários */
@media (min-width: 768px) {
  body {
    /* Mantém as mesmas propriedades */
  }
  
  * {
    /* Mantém o box-sizing */
  }
}

/* Grande (LG) - Laptops e monitores de tamanho médio */
@media (min-width: 992px) {
  body {
    /* Mantém as mesmas propriedades */
  }
  
  * {
    /* Mantém o box-sizing */
  }
}

/* Extra Grande (XL) - Monitores de desktop maiores e telas de alta resolução */
@media (min-width: 1200px) {
  body {
    /* Mantém as mesmas propriedades */
  }
  
  * {
    /* Mantém o box-sizing */
  }
}
