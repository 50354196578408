/* Extra Pequeno (XS) - Smartphones em modo retrato */
.Contato h2 {
  margin: 40px 10px 10px;
}

.Texto {
  margin: 0 10px 40px;
  text-align: justify;
}

/* Pequeno (SM) - Smartphones em modo paisagem e tablets em modo retrato */
@media (min-width: 576px) {
  .Contato h2 {
    margin: 50px 20px 15px;
  }

  .Texto {
    margin: 0 20px 50px;
  }
}

/* Médio (MD) - Tablets em modo paisagem e dispositivos intermediários */
@media (min-width: 768px) {
  .Contato h2 {
    margin: 60px 40px 20px;
  }

  .Texto {
    margin: 0 40px 60px;
  }
}

/* Grande (LG) - Laptops e monitores de tamanho médio */
@media (min-width: 992px) {
  .Contato h2 {
    margin: 80px 100px 20px;
  }

  .Texto {
    margin: 0 100px 80px;
  }
}

/* Extra Grande (XL) - Monitores de desktop maiores e telas de alta resolução */
@media (min-width: 1200px) {
  .Contato h2 {
    margin: 100px 200px 20px;
  }

  .Texto {
    margin: 0 200px 100px;
  }
}

