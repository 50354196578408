/* Estilos globais para o rodapé */
.Footer {
  padding: 40px 20px; /* Adiciona espaçamento interno ao contêiner */
  background-color: #000000; /* Define a cor de fundo como preto */
  text-align: center; /* Centraliza o conteúdo */
}

.imagem {
  display: flex; /* Define um layout flexível para o contêiner */
  justify-content: left; /* Alinha os itens à esquerda */
  gap: 20px; /* Define o espaçamento entre os elementos */
  margin-bottom: 10px; /* Adiciona uma margem inferior */
  height: 40px; /* Define a altura do contêiner */
  padding: 0 0 0 10px; /* Adiciona padding à esquerda do contêiner */
}

.Footer h3 {
  font-size: 15px; /* Define o tamanho da fonte do texto */
  padding: 0 10px 0 200px; /* Adiciona padding interno ao texto */
  margin: 0; /* Remove margens */
  color: white; /* Define a cor do texto como branco */
  text-align: center; /* Centraliza o texto */
}

/* Extra Pequeno (XS) - Smartphones em modo retrato */
@media (max-width: 575px) {
  .Footer {
    padding: 20px 10px; /* Ajusta o espaçamento interno para dispositivos muito pequenos */
  }

  .imagem {
    flex-direction: column; /* Alinha as imagens verticalmente em telas pequenas */
    justify-content: center; /* Alinha os itens ao centro */
    height: auto; /* Ajusta a altura do contêiner para se adaptar ao conteúdo */
    padding: 0; /* Remove o padding */
  }

  .Footer h3 {
    font-size: 12px; /* Ajusta o tamanho da fonte para telas pequenas */
    padding: 0 5px; /* Ajusta o padding para telas pequenas */
    text-align: center; /* Garante a centralização do texto */
  }
}

/* Pequeno (SM) - Smartphones em modo paisagem e tablets em modo retrato */
@media (min-width: 576px) and (max-width: 767px) {
  .Footer {
    padding: 30px 15px; /* Ajusta o espaçamento interno para smartphones em modo paisagem */
  }

  .imagem {
    height: 30px; /* Ajusta a altura do contêiner para smartphones */
    padding: 0 0 0 5px; /* Ajusta o padding à esquerda */
  }

  .Footer h3 {
    font-size: 14px; /* Ajusta o tamanho da fonte */
    padding: 0 10px; /* Ajusta o padding */
  }
}

/* Médio (MD) - Tablets em modo paisagem e dispositivos intermediários */
@media (min-width: 768px) and (max-width: 991px) {
  .Footer {
    padding: 35px 20px; /* Ajusta o espaçamento interno para tablets e dispositivos intermediários */
  }

  .imagem {
    height: 35px; /* Ajusta a altura do contêiner */
  }

  .Footer h3 {
    font-size: 15px; /* Mantém o tamanho da fonte */
    padding: 0 20px 0 100px; /* Ajusta o padding */
  }
}

/* Grande (LG) - Laptops e monitores de tamanho médio */
@media (min-width: 992px) and (max-width: 1199px) {
  .Footer {
    padding: 40px 20px; /* Mantém o espaçamento interno */
  }

  .imagem {
    height: 40px; /* Mantém a altura do contêiner */
  }

  .Footer h3 {
    font-size: 15px; /* Mantém o tamanho da fonte */
    padding: 0 20px 0 150px; /* Ajusta o padding */
  }
}

/* Extra Grande (XL) - Monitores de desktop maiores e telas de alta resolução */
@media (min-width: 1200px) {
  .Footer {
    padding: 40px 20px; /* Mantém o espaçamento interno */
  }

  .imagem {
    height: 40px; /* Mantém a altura do contêiner */
  }

  .Footer h3 {
    font-size: 15px; /* Mantém o tamanho da fonte */
    padding: 0 20px 0 200px; /* Mantém o padding original */
  }
}

