/* Estilos globais para o banner */
.Banner {
  width: 100%; /* Define a largura total do banner */
  max-height: 400px; /* Define a altura máxima do banner */
  overflow: hidden; /* Garante que a imagem não cause rolagem */
  margin-bottom: 0; /* Remove a margem inferior */
}

.Banner img {
  width: 100%; /* Define a largura total da imagem dentro do banner */
  height: auto; /* Ajusta a altura da imagem proporcionalmente */
  max-height: 400px; /* Define a altura máxima da imagem */
  margin: 0; /* Remove a margem da imagem */
}

/* Estilos para o item do slide */
.slide-item {
  width: 100%; /* Define a largura total do item do slide */
  height: 350px; /* Define a altura do item do slide */
  object-fit: cover; /* Garante que a imagem cubra toda a área do slide sem distorção */
}

/* Extra Pequeno (XS) - Smartphones em modo retrato */
@media (max-width: 575px) {
  .Banner {
    max-height: 300px; /* Ajusta a altura máxima do banner em dispositivos muito pequenos */
  }

  .slide-item {
    height: 250px; /* Ajusta a altura do item do slide para dispositivos muito pequenos */
  }
}

/* Pequeno (SM) - Smartphones em modo paisagem e tablets em modo retrato */
@media (min-width: 576px) and (max-width: 767px) {
  .Banner {
    max-height: 350px; /* Ajusta a altura máxima do banner para smartphones em modo paisagem */
  }

  .slide-item {
    height: 300px; /* Ajusta a altura do item do slide para smartphones em modo paisagem */
  }
}

/* Médio (MD) - Tablets em modo paisagem e dispositivos intermediários */
@media (min-width: 768px) and (max-width: 991px) {
  .Banner {
    max-height: 375px; /* Ajusta a altura máxima do banner para tablets em modo paisagem e dispositivos intermediários */
  }

  .slide-item {
    height: 325px; /* Ajusta a altura do item do slide para tablets em modo paisagem e dispositivos intermediários */
  }
}

/* Grande (LG) - Laptops e monitores de tamanho médio */
@media (min-width: 992px) and (max-width: 1199px) {
  .Banner {
    max-height: 400px; /* Mantém a altura máxima do banner para laptops e monitores de tamanho médio */
  }

  .slide-item {
    height: 350px; /* Mantém a altura do item do slide para laptops e monitores de tamanho médio */
  }
}

/* Extra Grande (XL) - Monitores de desktop maiores e telas de alta resolução */
@media (min-width: 1200px) {
  .Banner {
    max-height: 400px; /* Mantém a altura máxima do banner para monitores maiores */
  }

  .slide-item {
    height: 350px; /* Mantém a altura do item do slide para monitores maiores */
  }
}

  